'use strict';
(function(){

class FindingsComponent {
  constructor() {
    this.message = 'Hello';
  }
}

angular.module('windmanagerApp')
  .component('findings', {
    templateUrl: 'app/findings/findings.html',
    controller: FindingsComponent
  });

})();
